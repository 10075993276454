<template>
  <v-app>
    <v-main class="main" :style="mainStyle">
      <v-container>
        <v-card class="rounded-xl pt-sm-12 pr-sm-7 pb-sm-9 pl-sm-15 pa-10 mt-10" style="max-width: 1200px;">
          <v-row align="start">
            <v-col md="6" sm="6" order-sm="1" order-md="1" order-lg="1" order-xl="1" cols="12" order="2">
              <WelcomeText />
              <div class="action">
                <v-btn color="indigo" data-test-id="btnCreateAccount" class="white--text" depressed @click="signup" large>Create account</v-btn>
                <v-btn color="indigo" data-test-id="btnLogIn"  outlined @click="login" large>Log in</v-btn>
              </div>
            </v-col>
            <v-col md="6" sm="6" align-self="center" order-sm="2" order-md="2" order-lg="2" order-xl="2" order="1" cols="12">
              <img :src="loginImageUrl" class="studio-img" alt="vibrant colourful music squiggles" />
              <img :src="loginLogoUrl" class="logo" :alt="`${appTitle} logo`">
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
 
<script>
import WelcomeText from "./WelcomeText.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "Welcome",
  components: { WelcomeText },
  computed: {
    ...mapState('brand', ['brandKey', 'appTitle', 'loginImageUrl', 'loginBackgroundImageUrl', 'loginLogoUrl']),
    mainStyle() {
      return {
        backgroundImage: `url('${this.loginBackgroundImageUrl}')`,
      };
    }
  },
  watch: {
    "$auth.isAuthenticated": {
      handler: function (val) {
        if (val) {
          this.$router.push("/").catch((err) => { /*don't care*/ });
        }
      },
      deep: true,
      immediate:true
    },
  },
  methods: {
    ...mapActions('brand', ['initialise']),
    login() {
      this.$auth.doLogin();
    },
    signup() {
      this.$auth.doLogin({ screen_hint: 'signup' });
    }
  },
  created() {
    this.initialise();
  }
};
</script>

<style lang="scss" scoped>
.main {
  background-size: cover;
  background-repeat: no-repeat;
}

.studio-img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  align-content: center;
}

.logo {
  position: absolute;
  top: 30px;
  right: 30px;
  height: 64px;
  width: auto;
}

.action {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
</style>
