export const BRANDS = {
  ma: {
    appTitle: 'MusicApprentice',
    appSubTitle: 'Music',
    appLogoUrl: '/logo/ma/square-cyan.svg',
    loginStyle: 'standard',
    loginImageUrl: '/brand/ma/login-image.png',
    loginBackgroundImageUrl: '/brand/ma/login-bg.png',
    loginLogoUrl: '/logo/ma/square-cyan.svg',
    onboardBackgroundImageUrl: '/brand/ma/onboard-side.png',
  },
  ha: {
    appTitle: 'HistoryApprentice',
    appSubTitle: 'History',
    appLogoUrl: '/logo/ma/square-cyan.svg',
    loginStyle: 'standard',
    loginImageUrl: '/brand/ha/login-image.webp',
    loginBackgroundImageUrl: '/brand/ha/login-bg.png',
    loginLogoUrl: '/logo/ma/square-cyan.svg',
    onboardBackgroundImageUrl: '/brand/ha/onboard-side.png',
  },
  ea: {
    appTitle: 'EnglishApprentice',
    appSubTitle: 'English',
    appLogoUrl: '/logo/ma/square-cyan.svg',
    loginStyle: 'standard',
    loginImageUrl: '/brand/ea/login-image.webp',
    loginBackgroundImageUrl: '/brand/ea/login-bg.png',
    loginLogoUrl: '/logo/ma/square-cyan.svg',
    onboardBackgroundImageUrl: '/brand/ea/onboard-side.png',
  },
  sgms: {
    appTitle: `MusicApprentice: Mozart's Sister`,
    appSubTitle: 'Music',
    appLogoUrl: '/logo/ma/square-cyan.svg',
    loginStyle: 'banner',
    loginBackgroundImageUrl: '/brand/sgms/login-bg.jpg',
    loginLogoUrl: '/logo/ma/square-cyan.svg',
    onboardBackgroundImageUrl: '/brand/sgms/onboard-side.png',
  },
}

export default {
  namespaced: true,
  state: {
    brandKey: null,
    appTitle: null,
    appSubTitle: null,
    appLogoUrl: null,
    loginImageUrl: null,
    loginBackgroundImageUrl: null,
    loginLogoUrl: null,
    loginStyle: null,
    onboardBackgroundImageUrl: null,
  },
  mutations: {
    setBrandKey(state, brandKey) {
      state.brandKey = brandKey;
      state.appTitle = BRANDS[brandKey].appTitle;
      state.appSubTitle = BRANDS[brandKey].appSubTitle;
      state.appLogoUrl = BRANDS[brandKey].appLogoUrl;
      state.loginImageUrl = BRANDS[brandKey].loginImageUrl;
      state.loginBackgroundImageUrl = BRANDS[brandKey].loginBackgroundImageUrl;
      state.loginLogoUrl = BRANDS[brandKey].loginLogoUrl;
      state.loginStyle = BRANDS[brandKey].loginStyle;
      state.onboardBackgroundImageUrl = BRANDS[brandKey].onboardBackgroundImageUrl;
    },
  },
  actions: {
    async initialise({commit}) {
      const brandKey = window.BRAND_KEY;
      commit('setBrandKey', brandKey);
    }
  }
}