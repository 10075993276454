<template>
  <v-app>
    <NavBar :drawerOpen="drawerOpen" />
    <AppBar @toggleDrawer="toggleDrawer" />

    <v-main>
      <v-container fluid>
        <router-view></router-view>
        <SpeechPlayer />
      </v-container>
    </v-main>

    <v-footer app>
    </v-footer>

    <v-snackbar :value="snackbarVisible" :color="snackbarColor" :timeout="snackbarTimeout">
      {{ snackbarMessage }}
    </v-snackbar>

    <v-dialog :value="popupVisible" persistent width="300">
      <v-card color="primary" dark class="pa-6">
        <v-card-text>
          {{ popupMessage }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog :value="alertVisible" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ alertTitle }}
        </v-card-title>

        <v-card-text>
          <p class="pt-4">{{ alertMessage }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeAlert">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import {mapActions, mapState} from "vuex";
import AppBar from "./components/AppBar.vue";
import SpeechPlayer from "../common/SpeechPlayer.vue";

export default {
  components: {SpeechPlayer, NavBar, AppBar},
  computed: {
    ...mapState('ui', ['snackbarVisible', 'snackbarMessage', 'snackbarColor', 'snackbarTimeout', 'popupVisible', 'popupMessage', 'breadcrumbs', 'loading', 'alertVisible', 'alertMessage', 'alertTitle']),
  },
  methods: {
    ...mapActions('ui', ['closeAlert']),
    ...mapActions('brand', ['initialise']),
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
  },
  data () {
    return {
      drawerOpen: true
    }
  },
  created() {
    this.initialise();
  }
}
</script>

<style lang="scss">

</style>