import Vuex from 'vuex'
import Vue from "vue";
import {getWithId} from "../request";
import {datadogRum} from "@datadog/browser-rum";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    navBarContent: {},
    appName: "",
    appTitle: "",
    apiVersion: "",
    apiEnv: "",
    appVersion: window.MATT_APP_VERSION,
    appEnv: window.MATT_APP_ENV,
    pathways: [],
    syllabus: [],
    dictionary: {},
    featureToggles: {},
    onboardingTasks: [],
    user: {}
  },
  getters: {
    hasSystemLockout: state => !!state.featureToggles['system.lockout'],
    hasSystemLocking: state => !!state.featureToggles['system.locking'],
    hasSectionHeaders: state => !!state.featureToggles['section.headers'],
    hasWordOfTheDayCard: state => !!state.featureToggles['dashboard.wordOfTheDay'],
    hasDiagnosticCard: state => !!state.featureToggles['dashboard.diagnostic'],
    showHomeForTeachers: state => !!state.featureToggles['dashboard.showForTeachers'],
    userDisplayName: state => {
      const userData = state.user['https://musicapprentice.com.au/userdata'];
      if(userData?.teacher?.displayName) {
        return userData.teacher.displayName;
      } else if (userData?.student) {
        return `${userData.student?.firstName} ${userData.student?.lastName}`;
      } else if (userData?.account) {
        return `${userData.account?.firstName} ${userData.account?.lastName}`;
      } else if(state.user.name) {
        return state.user.name;
      } else {
        return "User";
      }
    },
    userAvatarLabel: (state,getters) => {
      const displayName = getters.userDisplayName;
      return displayName.split(' ').map(name => name[0]).join('');
    },
    userAvatarImage: state => {
      if(state.user.picture) {
        return state.user.picture;
      }
    }
  },
  mutations: {
    setData(state, data) {
      state.appName = data.appName;
      state.appTitle = data.appTitle;
      state.apiEnv = data.apiEnv;
      state.apiVersion = data.apiVersion;
      state.navBarContent = data.navBarContent;
      state.pathways = data.pathways;
      state.dictionary = data.dictionary
      state.syllabus = data.syllabus;
      state.featureToggles = data.featureToggles;
      state.onboardingTasks = data.onboardingTasks;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    async initialise({commit}, options) {
      const auth = options.auth || this.$auth;

      try {
        const response = await getWithId(auth, `${window.MATT_USER_API}/appData`);

        commit('setData', response.data.data);
        commit('setUser', auth.user);

        const ddUser = {id: auth.user.sub, email: auth.user.email, name: auth.user.name};
        datadogRum.setUser(ddUser);
      } catch (err) {
        this.$auth.logout();
        this.$router.push({ name:'welcome' }).catch(() => {});
      }
    }
  },
};
