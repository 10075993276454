import softAuthGuard from './soft-auth-guard.js';
import App from "../components/studio/App.vue";
import StandardLogin from "../components/studio/login/StandardLogin.vue";
import Home from "../components/studio/home/Home.vue";
import BannerLogin from "../components/studio/login/BannerLogin.vue";
import {BRANDS} from "../stores/studio/brand";

const brandKey = window.BRAND_KEY;
const brand = BRANDS[brandKey];

export default [
  {
    path: "/onboard",
    beforeEnter: softAuthGuard('welcome'),
    name: "onboarding",
    component: () => import("../components/studio/onboard/Onboard.vue"),
  },
  {
    path: "/",
    redirect: {name:'home'},
    name: "root",
    component: App,
    beforeEnter: softAuthGuard('welcome'),
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
        meta: {
          showBreadcrumbs: false,
        }
      }, {
        path: "/onboard",
        name: "onboarding",
        component: () => import("../components/studio/onboard/Onboard.vue"),
        meta: {
          showBreadcrumbs: false,
        }
      }, {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../components/studio/dashboard/TeacherDashboard.vue"),
        meta: {
          showBreadcrumbs: false,
        }
      }, {
        path: "/results",
        name: "results",
        component: () => import("../components/studio/results/ResultsLayout.vue"),
        children: [
          {
            path: "index",
            name: "quizSubmissions",
            component: () => import("../components/studio/results/StudentSubmissionsIndex.vue"),
          }, {
            path: "quiz/:quizId",
            name: "viewLastQuizSubmission",
            component: () => import("../components/studio/results/QuizSubmission.vue"),
          }, {
            path: "submission/:submissionId",
            name: "viewQuizSubmission",
            component: () => import("../components/studio/results/QuizSubmission.vue"),
          }
        ]
      }, {
        path: "/tasks",
        name: "tasks",
        component: () => import("../components/studio/tasks/Tasks.vue"),
        redirect: '/tasks/me',
        children: [
          {
            path: "me",
            name: "myTasks",
            component: () => import("../components/studio/tasks/MyTasks.vue"),
            meta: {
              title: 'My Tasks',
            }
          }, {
            path: ":taskId/resource/:resourceId",
            name: "taskResource",
            component: () => import("../components/studio/tasks/TaskResource.vue"),
            meta: {
              title: 'My Tasks > :resourceTitle',
            }
          }
        ]
      }, {
        path: "/teacher",
        component: () => import("../components/studio/teacher/Layout.vue"),
        children: [
          {
            path: "enrolments",
            name: "enrolments",
            component: () => import("../components/studio/teacher/Enrolments.vue"),
            meta: {
              showBreadcrumbs: false,
            }
          }, {
            path: "cohorts",
            name: "cohorts",
            component: () => import("../components/studio/teacher/Cohorts.vue"),
            meta: {
              showBreadcrumbs: false,
            }
          }, {
            path: "cohorts/:cohortId",
            name: "cohortEdit",
            component: () => import("../components/studio/teacher/CohortEdit.vue"),
          }, {
            path: "progress",
            name: "studentProgress",
            component: () => import("../components/studio/teacher/StudentProgress.vue"),
          }, {
            path: "assignTasks",
            name: "assignTasks",
            component: () => import("../components/studio/teacher/AssignTasks.vue"),
            meta: {
              showBreadcrumbs: false,
            }
          }, {
            path: "assignedTasks",
            name: "assignedTasks",
            component: () => import("../components/studio/teacher/EditTasks.vue"),
            meta: {
              showBreadcrumbs: false,
            }
          }, {
            path: "student/:studentId/pathway/:pathwaySlug",
            name: "studentPathway",
            component: () => import("../components/studio/teacher/StudentPathway.vue"),
          }, {
            path: "student/:studentId/manage",
            name: "studentManage",
            component: () => import("../components/studio/teacher/StudentManage.vue"),
          }, {
            path: "student/:studentId/tasks",
            name: "studentTasks",
            component: () => import("../components/studio/teacher/StudentTasks.vue"),
          }, {
            path: "student/:studentId/results",
            name: "studentResults",
            component: () => import("../components/studio/teacher/StudentResults.vue"),
          }, {
            path: "student/:studentId/report",
            name: "studentReport",
            component: () => import("../components/studio/teacher/StudentReportWritten.vue"),
          }, {
            path: "student/:studentId/data",
            name: "studentReportData",
            component: () => import("../components/studio/teacher/StudentReportData.vue"),
          }, {
            path: "student/:studentId/quiz/:quizId",
            name: "studentSubmission",
            component: () => import("../components/studio/results/QuizSubmission.vue"),
          }, {
            path: "results/cohort",
            name: "quizSubmissionsForCohort",
            component: () => import("../components/studio/results/CohortSubmissionsIndex.vue"),
          }, {
            path: "results/:studentId",
            name: "quizSubmissionsForStudent",
            component: () => import("../components/studio/results/StudentSubmissionsIndex.vue"),
          }

        ]
      }, {
        path: "/profile",
        name: "profile",
        component: () => import("../components/studio/profile/Profile.vue"),
        children: [
          {
            path: "account",
            name: "account",
            component: () => import("../components/studio/profile/AccountDetails.vue"),
            meta: {
              showBreadcrumbs: false,
              guard: false
            }
          }, {
            path: "subscription",
            name: "subscription",
            component: () => import("../components/studio/profile/Subscription.vue"),
            meta: {
              showBreadcrumbs: false,
              guard: false
            }
          }, {
            path: "teachers",
            name: "myTeachers",
            component: () => import("../components/studio/profile/MyTeachers.vue"),
            meta: {
              showBreadcrumbs: false,
            }
          }
        ]
      },{
        path: "/category/:slug",
        name: "category",
        component: () => import("../components/studio/categoryBrowser/Layout.vue"),
        children: [
          {
            path: ':path*/resource/:resourceId',
            name: 'categoryResource',
            component: () => import("../components/studio/categoryBrowser/Resource.vue"),
          }, {
            path: ':path*',
            name: 'categoryIndex',
            component: () => import("../components/studio/categoryBrowser/Index.vue"),
          }
        ]
      }, {
        path: "/pathways",
        name: "pathways",
        component: () => import("../components/studio/pathway/Pathways.vue"),
      }, {
        path: "/pathway/:pathwaySlug",
        name: "pathway",
        component: () => import("../components/studio/pathway/Pathway.vue"),
      }, {
        path: "/pathway/:pathwaySlug/module/:moduleSlug",
        name: "module",
        component: () => import("../components/studio/module/Module.vue"),
      }, {
        path: "/pathway/:pathwaySlug/module/:moduleSlug/resource/:resourceSlug",
        name: "resource",
        component: () => import("../components/studio/resource/Resource.vue"),
      }, {
        path: "/page/:resourceSlug",
        name: "page",
        component: () => import("../components/studio/resource/Resource.vue"),
      }, {
        path: "/feedback",
        name: "feedback",
        component: () => import("../components/studio/feedback/Feedback.vue"),
      }, {
        path: "/diagnostic/:slug",
        name: "diagnostic",
        component: () => import("../components/studio/diagnostic/Diagnostic.vue"),
      }
    ],
  }, {
    path: "/welcome",
    name: "welcome",
    component: brand.loginStyle === 'standard' ? StandardLogin : BannerLogin,
  }
];