import Vuex from 'vuex'
import Vue from "vue";
import {delWithId, getWithId, postWithId, putWithId} from "../request.js";
import ui from "./ui.js";
import {getFromLocalStorage} from "../../utils/browser-utils.js";

const {MATT_USER_API, MATT_CONTENT_API} = window;

Vue.use(Vuex);

export default {
  namespaced: true,
  modules: {
    ui
  },
  state: {
    myCohorts: [],
    activeCohort: getFromLocalStorage('activeCohort'),
    myStudents: [],
    selectedStudents: [],
    activeStudent: null,
    studentProgress: null,
    studentTasks: null,
    studentPathway: null,
    studentPathwayMap: null,
    studentTagReport: null,
    studentPathwayReport: null,
    studentReportWritten: null,
    newTask: {},
    myAssignedTasks: [],
    activeTask: null,
    matchingResources: [],
    selectedResources: [],
    assignedTasksResponse: {},
    searchLoading: false
  },
  getters: {
    availableCohorts(state) {
      return state.myCohorts.filter(c => c.state !== 'closed');
    }
  },
  mutations: {
    setMyStudents(state, data) {
      state.myStudents = data;
    },
    setSelectedStudents(state, data) {
      state.selectedStudents = data;
    },
    setNewTasks(state, data) {
      state.newTasks = data;
    },
    setActiveStudent(state, data) {
      state.activeStudent = data;
    },
    setMatchingResources(state, data) {
      state.matchingResources = data;
      state.searchLoading = false;
    },
    setSelectedResources(state, data) {
      state.selectedResources = data;
    },
    setAssignedTasksResponse(state, data) {
      state.assignedTasksResponse = data;
    },
    setStudentProgress(state, data) {
      state.studentProgress = data;
    },
    setStudentPathway(state, data) {
      state.studentPathway = data;
    },
    setStudentTasks(state, data) {
      state.studentTasks = data;
    },
    setStudentTagReport(state, data) {
      state.studentTagReport = data;
    },
    setStudentPathwayReport(state, data) {
      state.studentPathwayReport = data;
    },
    setSearchLoading(state, data) {
      state.searchLoading = data;
    },
    setCohorts(state, data) {
      state.myCohorts = data;
    },
    setActiveCohort(state, data) {
      state.activeCohort = data;
      window.localStorage.setItem('activeCohort', JSON.stringify(data));
    },
    setStudentReportWritten(state, data) {
      state.studentReportWritten = data;
    },
    setStudentPathwayMap(state, data) {
      state.studentPathwayMap = data;
    },
    setMyAssignedTasks(state, data) {
      state.myAssignedTasks = data;
    },
    setActiveTask(state, data) {
      state.activeTask = data;
    }
  },
  actions: {
    resetSearch({commit}) {
      commit('setMatchingResources', []);
      commit('setSearchLoading', false);
    },
    async selectTask({commit}, task) {
      commit('setActiveTask', task);
    },
    async saveActiveTask({dispatch, state}) {
      await dispatch('ui/setLoading', 'Saving task...');

      try {
        const task = state.activeTask;
        const response = await putWithId(this._vm.$auth, `${MATT_USER_API}/tasks/${task.id}`, task);
        dispatch('ui/showSuccess', 'Task successfully saved!');
        return response.data;
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Error saving task');
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async assignTasks({commit, dispatch}, data) {
      await dispatch('ui/setLoading', 'Assigning tasks...');

      try {
        const response = await postWithId(this._vm.$auth, `${MATT_USER_API}/tasks`, data);
        commit('setAssignedTasksResponse', response.data);
        dispatch('ui/showSuccess', response.data.message);
        return response.data;
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadMyAssignedTasks({commit, dispatch}) {
      await dispatch('ui/setLoading', 'Loading tasks...');

      try {
        const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/tasks/me/assigned`);
        commit('setMyAssignedTasks', response.data.tasks);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async deleteTask({dispatch}, taskId) {
      await dispatch('ui/setLoading', 'Deleting task...');

      try {
        const response = await delWithId(this._vm.$auth, `${MATT_USER_API}/tasks/${taskId}`);
        dispatch('ui/showSuccess', 'Task successfully deleted!');
        dispatch('loadMyAssignedTasks');
        return response.data;
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Error deleting task');
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async searchResources({commit}, params) {
      try {
        commit('setSearchLoading', true);
        const response = await getWithId(this._vm.$auth, `${MATT_CONTENT_API}/resources/search`, {params});
        commit('setMatchingResources', response.data);
        return response.data;
      } finally {
        commit('setSearchLoading', false);
      }
    },
    async loadStudentTagReport({commit, dispatch}, studentId) {
      await dispatch('ui/setLoading', 'Loading students...');
      await dispatch('loadStudent', studentId);

      try {
        const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/report/${studentId}/tags`);
        commit('setStudentTagReport', response.data);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadStudentPathwayReport({commit, dispatch}, studentId) {
      await dispatch('ui/setLoading', 'Loading students...');
      await dispatch('loadStudent', studentId);

      try {
        const response = await getWithId(this._vm.$auth, `${window.MATT_URL}/ai/v1/pathway/${studentId}`);
        commit('setStudentPathwayReport', response.data);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadMyStudents({commit, dispatch}) {
      await dispatch('ui/setLoading', 'Loading students...');

      try {
        const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/teacher/students`);
        commit('setMyStudents', response.data.students);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadMyCohorts({commit}) {
      const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/teacher/me/cohorts`);
      commit('setCohorts', response.data.cohorts);
    },
    newCohort({commit}) {
      const cohort = {
        name: '',
        state: 'open',
        students: [],
      };
      commit('setActiveCohort', cohort);
    },
    async deleteCohort({dispatch}, cohortId) {
      try {
        const response = await delWithId(this._vm.$auth, `${MATT_USER_API}/teacher/me/cohorts/${cohortId}`);
        await dispatch('ui/showSuccess', 'Cohort successfully deleted!');
        await dispatch('loadMyCohorts');
        return response;
      } catch (err) {
        const message = err.response?.data?.message || 'Error deleting cohort';
        dispatch('ui/showError', message);
      }
    },
    async saveCohort({dispatch, commit, state}) {
      await dispatch('ui/setLoading', 'Saving cohort...');

      try {
        const cohort = state.activeCohort;

        if(cohort.id) {
          const response = await putWithId(this._vm.$auth, `${MATT_USER_API}/teacher/me/cohorts/${cohort.id}`, cohort);
          commit('setActiveCohort', response.data.cohort);
        } else {
          const response = await postWithId(this._vm.$auth, `${MATT_USER_API}/teacher/me/cohorts`, cohort);
          commit('setActiveCohort', response.data.cohort);
        }
      } catch(err) {
        const message = err.response?.data?.message || 'Error saving cohort';
        dispatch('ui/showError', message);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadCohort({commit}, cohortId) {
      const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/teacher/me/cohorts/${cohortId}`);
      commit('setActiveCohort', response.data.cohort);
    },
    async removeStudentFromActiveCohort({dispatch, state}, studentId) {
      try {
        const cohortId = state.activeCohort.id;
        await delWithId(this._vm.$auth, `${MATT_USER_API}/teacher/me/cohorts/${cohortId}/students`, {data:[{studentId}]});
        await dispatch('loadCohort', cohortId);
        dispatch('ui/showSuccess', 'Student successfully removed from cohort!');
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Error removing student from cohort');
      }
    },
    async removeTask({dispatch}, taskId) {
      await dispatch('ui/setLoading', 'Removing task...');

      try {
        const response = await delWithId(this._vm.$auth, `${MATT_USER_API}/tasks/${taskId}`);
        dispatch('ui/showSuccess', 'Task successfully removed!');
        return response.data;
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Error removing task');
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async addStudentToCohort({dispatch}, {studentId, cohortId}) {
      return dispatch('addStudentsToCohort', {studentIds: [studentId], cohortId});
    },
    async addStudentsToCohort({dispatch}, {studentIds, cohortId}) {
      try {
        const _cohortId = cohortId || this.state.teacher.activeCohort.id;
        const payload = studentIds.map(studentId => ({studentId}))
        await postWithId(this._vm.$auth, `${MATT_USER_API}/teacher/me/cohorts/${_cohortId}/students`, payload);
        await dispatch('loadCohort', _cohortId);
        dispatch('ui/showSuccess', 'Student(s) successfully added to cohort!');
      } catch (err) {
        const message = err.response?.data?.message || 'Error adding student(s) to cohort';
        dispatch('ui/showError', message);
      }
    },
    async generateReport({dispatch, commit}, studentId) {
      await dispatch('ui/setLoading', 'Generating report, please be patient, this can take up to a minute!');

      try {
        const response = await getWithId(this._vm.$auth, `${window.MATT_URL}/ai/v1/report/${studentId}`);
        commit('setStudentReportWritten', response.data);
        return response.data;
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Error generating report');
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async removeStudent({dispatch}, studentUserId) {
      await dispatch('ui/setLoading', 'Removing student...');

      try {
        const response = await delWithId(this._vm.$auth, `${MATT_USER_API}/teacher/students/${studentUserId}`);
        dispatch('ui/showSuccess', 'Student successfully removed!');
        dispatch('loadMyStudents');
        return response.data;
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Error removing student');
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadStudent({state, commit, dispatch}, studentId) {
      let ownLoading = false;
      if(!state.searchLoading) {
        await dispatch('ui/setLoading', 'Loading student data...');
        ownLoading = true;
      }

      try {
        if(!state.myStudents.length) {
          await dispatch('loadMyStudents');
        }
        const student = state.myStudents.find(s => s.userId === studentId);
        commit('setActiveStudent', student);
      } finally {
        ownLoading && await dispatch('ui/setLoading', null);
      }
    },
    async loadTasksForStudent({dispatch, commit}, studentId) {
      await dispatch('ui/setLoading', 'Loading tasks for student...');
      await dispatch('loadStudent', studentId);

      try {
        const url = `${MATT_USER_API}/report/tasks/${studentId}`;
        const response = await getWithId(this._vm.$auth, url);
        const { tasks, quizSubmissions } = response.data;

        tasks.forEach(task => {
          task.quizSubmission = quizSubmissions.find(qs => qs.quiz.slug === task.resource.slug);
        })

        commit('setStudentTasks', tasks);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadPathwaysMapForStudent({dispatch, commit}, studentId) {
      await dispatch('ui/setLoading', 'Loading pathway for student...');
      await dispatch('loadStudent', studentId);

      try {
        const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/pathways/${studentId}`);
        commit('setStudentPathwayMap', response.data);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadPathwayForStudent({dispatch, commit}, { pathwaySlug, studentId }) {
      await dispatch('ui/setLoading', 'Loading pathway for student...');
      await dispatch('loadStudent', studentId);

      try {
        const url = `${MATT_USER_API}/pathway/${pathwaySlug}/${studentId}`;
        const response = await getWithId(this._vm.$auth, url);
        const progress = response.data;
        commit('setStudentProgress', progress);

        const pathwayResponse = await getWithId(this._vm.$auth, `${MATT_CONTENT_API}/pathway/${pathwaySlug}`);
        commit('setStudentPathway', pathwayResponse.data);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async unlockResource({dispatch}, {studentId, slug}) {
      await dispatch('ui/setLoading', 'Unlocking resource...');

      try {
        const response = await postWithId(this._vm.$auth, `${MATT_USER_API}/unlock`, {studentId, slug});
        await dispatch('ui/showSuccess', response.data.message);
        return response.data;
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Error unlocking resource');
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async removeUnlock({dispatch}, { unlockId }) {
      await dispatch('ui/setLoading', 'Removing unlock...');

      try {
        const response = await delWithId(this._vm.$auth, `${MATT_USER_API}/unlock/${unlockId}`);
        dispatch('ui/showSuccess', response.data.message);
        return response.data;
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Error removing unlock');
      } finally {
        await dispatch('ui/setLoading', null);
      }
    }
  },
};
