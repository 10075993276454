import axios from "axios";

const addAuthHeader = async(auth, options = {}) => {
  try {
    const config = Object.assign({}, options);

    const token = await auth.getDetailedTokenSilently();

    if (token.message === 'consent_required') {
      throw new Error('consent required, reattempt login');
    }

    config.headers = config.headers || {};

    if (token.type === 'bearer' && token.access_token) {
      config.headers.Authorization = `Bearer ${token.access_token}`;
    }

    if (options.withId && token.id_token) {
      config.headers['x-id-token'] = token.id_token;
    }

    return config;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to add auth headers');
  }
}

export const getAccessToken = async(auth) => {
  const token = await auth.getDetailedTokenSilently();

  if(token.type === 'bearer' && token.access_token){
    return token.access_token;
  }
}

export const list = async (auth, url, options) => {
  const config = await addAuthHeader(auth, options);
  return axios.get(url, config);
}

export const getWithId = async (auth, url, options) => {
  const config = await addAuthHeader(auth, {...options, withId: true});
  return axios.get(url, config);
}

export const get = async (auth, url, options) => {
  const config = await addAuthHeader(auth, options);
  return axios.get(url, config);
}

export const delWithId = async(auth, url, options) => {
  const config = await addAuthHeader(auth, {...options, withId: true});
  return axios.delete(url, config);
}

export const postWithId = async(auth, url, data, options) => {
  const config = await addAuthHeader(auth, {...options, withId: true});
  return axios.post(url, data, config);
}

export const post = async(auth, url, data, options) => {
  const config = await addAuthHeader(auth, options);
  return axios.post(url, data, config);
}

export const putWithId = async(auth, url, data, options) => {
  const config = await addAuthHeader(auth, {...options, withId: true});
  return axios.put(url, data, config);
}

export const put = async(auth, url, data, options) => {
  const config = await addAuthHeader(auth, options);
  return axios.put(url, data, config);
}

export const putFileNoAuth = async(url, file) => {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/octet-stream'
    }
  };

  return axios.put(url, file, config);
}

export const patch = async(auth, url, data, options) => {
  const config = await addAuthHeader(auth, options);
  return axios.patch(url, data, config);
}

export const patchWithId = async(auth, url, data, options) => {
  const config = await addAuthHeader(auth, {...options, withId: true});
  return axios.patch(url, data, config);
}

export const saveResource = async(auth, resource) => {
  let response;

  if (resource.id) {
    // MATT_CONTENT_API is globally defined
    // eslint-disable-next-line no-undef
    response = await put(auth, `${MATT_CONTENT_API}/resources/${resource.id}`, resource);
  } else {
    // eslint-disable-next-line no-undef
    response = await post(auth, `${MATT_CONTENT_API}/resources`, resource);
  }

  if (response.data.id) {
    return response.data;
  } else {
    //failure
  }
}