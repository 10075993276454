<template>
  <v-app-bar app>
    <v-app-bar-nav-icon @click.stop="$emit('toggleDrawer')"></v-app-bar-nav-icon>
    <v-toolbar-title>{{ appTitle }}</v-toolbar-title>

    <v-breadcrumbs v-if="showBreadcrumbs" :items="breadcrumbItems" large divider=">">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.to" exact>
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-spacer></v-spacer>

    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" elevation="3" class="mr-1">
          <v-avatar color="primary">
            <img v-if="userAvatarImage" :src="userAvatarImage" :alt="userDisplayName" />
            <span v-else>{{ userAvatarLabel }}</span>
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="navigateToProfile">
          <v-list-item-title class="font-weight-bold">{{ userDisplayName }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="canAccessCms" @click="navigateToCms">
          <v-list-item-title>CMS</v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigateToGradus">
          <v-list-item-title>Gradus Software</v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigateToFeedback">
          <v-list-item-title>Feedback</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!isAuthenticated" @click="doLogin">
          <v-list-item-title>Log in</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isAuthenticated" @click="doLogout">
          <v-list-item-title>Log out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import PermissionsMixin from "../../common/permissions-mixin.js";

export default {
  name: "AppBar",
  mixins: [PermissionsMixin],
  methods: {
    navigateToGradus() {
      window.open('https://www.gradussoftware.com.au/', '_gradus');
    },
    navigateToCms() {
      window.open('/cms', '_cms');
    },
    navigateToProfile() {
      this.$router.push({ name: 'account' }).catch(() => {});
    },
    navigateToFeedback() {
      this.$router.push({ name: 'feedback' }).catch(() => {});
    },
    doLogout() {
      this.$auth.logout({ returnTo: `${location.origin}${location.pathname}` });
    },
    doLogin() {
      this.$router.push({ name:'welcome' }).catch(() => {});
    },
  },
  computed: {
    ...mapState('ui', ['breadcrumbs']),
    ...mapState('brand', ['appTitle']),
    ...mapGetters('app', ['userDisplayName', 'userAvatarImage', 'userAvatarLabel']),
    breadcrumbItems() {
      const currentRouteIdx = this.breadcrumbs.findIndex(b => b.route.name === this.$route.name);
      return this.breadcrumbs.filter((b,idx) => idx <= currentRouteIdx).map(b => ({ text: b.title, to: b.route }));
    },
    routeMeta() {
      return this.$route?.meta || {};
    },
    showBreadcrumbs() {
      return this.routeMeta.showBreadcrumbs ?? true;
    }
  }
}
</script>
